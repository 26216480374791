/*.Timeline {
  height: auto;
  width: auto;
  border-spacing: 0px;
  border-collapse: collapse;
  margin-top: 75px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  background-color: white;
}
*/

.Timeline {
  margin-top: 75px;
}

ul.no-bullets {
  list-style-type: none;
  padding-top: 75px;
  padding: 10px;
  margin-bottom: 100px;
}
