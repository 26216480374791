.postCard {
  width: 80%;
  margin-left: 10%;
}
/*
.detailsAccordian {
  background-color: rgba(255,255,255,255);
  width: 80%;
  margin-left: 10%;
}
*/

ul.replies-no-bullets {
  list-style-type: none;
}
